<template>
  <div class="flex flex-col justify-center items-center min-h-screen">
    <h1 class="text-2xl font-bold">
      Server Status
    </h1>

    <div class="rounded-lg shadow-2xl border w-full max-w-2xl divide-y overflow-hidden mt-12">
      <div class="flex items-center justify-between space-x-4 p-4 sm:px-6 bg-gray-100">
        <div class="font-bold">
          MyVends Server
        </div>
        <div
          v-if="state.isOnline"
          class="flex items-center space-x-2"
        >
          <span class="block w-2 h-2 bg-green-600 rounded-full relative mt-0.5">
            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75" />
          </span>

          <span class="uppercase text-sm font-bold text-green-600 mt-px">
            Online
          </span>
        </div>
        <div
          v-else
          class="flex items-center space-x-2"
        >
          <span class="block w-2 h-2 bg-red-600 rounded-full relative mt-0.5">
            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75" />
          </span>

          <span class="uppercase text-sm font-bold text-red-600 mt-px">
            Offline
          </span>
        </div>
      </div>
      <div
        v-if="state.message"
        class="p-4 sm: p-6 bg-white"
      >
        <!-- <div class="text-gray-700 font-bold uppercase text-sm">
          Message
        </div> -->
        <div v-if="!state.isOnline" class="outage" v-html="state.message"></div>
        <div v-else>
          All systems working correctly.
        </div>
      </div>
    </div>

    <router-link
      v-if="state.isOnline"
      class="text-big-stone hover:text-blumine hover:underline"
      :to="{ name: 'Dashboard' }"
    >
      Go to Application
    </router-link>
  </div>
</template>

<script>
import checkApiStatus from '@/services/apiServerStatus.js'

export default {
  name: 'Status',

  setup() {
    const state = checkApiStatus().state()

    return {
      state,
    }
  }
}
</script>
